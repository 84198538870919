import React, { useState } from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { GeistProvider, CssBaseline } from '@geist-ui/react'
import { Sun, User, Moon, ShoppingBag, UserCheck, MessageCircle } from '@geist-ui/react-icons'

import { StatusBar, Style } from '@capacitor/status-bar';


class Layout extends React.Component {

 constructor(props){
  super(props);

  this.state = {
    showSignIn: false,
    mode: 'dark'
  }
}

 async updateStatusBar(mode) {
  this.setState({mode: mode});
  if(mode === 'dark') {
    try {
      await StatusBar.setStyle({ style: Style.Dark });
    }
    catch {
      console.log('on web');
    }
  }
  if(mode === 'light') {
    try {
      await StatusBar.setStyle({ style: Style.Light });
    }
    catch {
      console.log('on web');
    }
  }
}


  
  componentDidMount() {
      window.Pelcro = window.Pelcro ? window.Pelcro : {siteid: 1269};
      const Pelcro = window.Pelcro;
      Pelcro.siteid = 1269;
  }


  render() {

    const { children } = this.props

    return  <>
    <Helmet>
      <html lang="en" />
    </Helmet>
    <GeistProvider themeType={this.state.mode}>
    <CssBaseline />
    <header className={`site-header ${this.state.mode}`} id="header">
     </header>
    <div className={`site-wrapper ${this.state.mode}`} id="site-wrapper" onClick={() => this.setState({menu: false})}>
      {children}
    </div>
    </GeistProvider>
    </>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

